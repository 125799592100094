import React, { useCallback, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';

import { Button, FilterIcon } from '@kvdbil/components';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from '~/Locale';

import { FilterSwitch, MobileFilterSwitch } from './Shared/FilterSwitch';
import { useBuyMethodFilterSelect } from './FilterHooks/useBuyMethodFilterSelect';
import { useYearModelFilterSelect } from './FilterHooks/useYearModelFilterSelect';
import { useOtherFilterSelect } from './FilterHooks/useOtherFilterSelect';

import { FilterSelectItemProps, SliderType } from './types';
import { FilterModal } from '../FilterModal';
import { useSelector } from 'react-redux';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import {
  brandFilterOptionsSelector,
  facilityFilterOptionsSelector,
  familyNameFilterOptionsSelector
} from '~/App/shared/selectors/storeObjectsSelectors';
import {
  getAuctionTypes,
  getFuelOptions,
  getGearBoxOptions,
  getVehicleBodyTypes,
  getOtherOptions,
  getObjectTypeOptions,
  getCountiesOptions
} from '~/helpers/filterTranslation';
import { IncludeContent } from '~/App/shared/components/MediaQueryContainer';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { useGeneralMultiFilterSelect } from './FilterHooks/useGeneralMultiFilterSelect';
import { useGeneralSliderFilterSelect } from './FilterHooks/useGeneralSliderFilterSelect';
import { formatMoneyWithoutCurrency } from '~/helpers/currency';

const fadeIn = keyframes`
  from {

    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Fade = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  display: inline-block;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  animation: ${props => props.visible && fadeIn} 1s ease-in-out;
  transition: visibility 1s ease-in-out;
`;

const FilterSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FilterWrapper = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 0.5rem;
`;

const FilterToggleWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const FilterSelectItem = ({ hook, componentProps }: FilterSelectItemProps) => (
  <FilterSwitch
    {...componentProps}
    useFilterPropsHook={hook}
    key={componentProps.name}
  />
);

const MobileFilterSelectItem = ({
  hook,
  componentProps
}: FilterSelectItemProps) => (
  <MobileFilterSwitch
    {...componentProps}
    useFilterPropsHook={hook}
    key={componentProps.name}
  />
);

type Props = {
  mainCategory: MainCategory;
  searchQuery: string;
};

export const FilterSelector = ({ mainCategory, searchQuery }: Props) => {
  const { t } = useTranslation();
  const [expand, setExpanded] = useState(false);

  const selectedMainCategory = mainCategory;
  const selectedBrands = useParamFromQuery('brand')?.split(',') || [];

  const facilities = useSelector(facilityFilterOptionsSelector);
  const brands = useSelector(brandFilterOptionsSelector(selectedMainCategory));
  const familyNames = useSelector(
    familyNameFilterOptionsSelector(selectedBrands, selectedMainCategory)
  );

  const objectTypeOptions = useMemo(
    () => getObjectTypeOptions(t, mainCategory),
    [mainCategory, t]
  );

  const countiesOptions = useMemo(() => getCountiesOptions(), []);

  const buyMethods = useMemo(() => getAuctionTypes(t), [t]);

  const fuelOptions = useMemo(() => getFuelOptions(t), [t]);

  const gearboxesOptions = useMemo(() => getGearBoxOptions(t), [t]);

  const vehicleBodyTypes = useMemo(() => getVehicleBodyTypes(t), [t]);

  const otherOptions = useMemo(
    () => getOtherOptions(t, selectedMainCategory),
    [t, selectedMainCategory]
  );

  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

  const handleToggleModel = useCallback(() => {
    setFilterModalIsOpen(!filterModalIsOpen);
  }, [filterModalIsOpen]);

  // TODO: Might need to extend this in the future,
  // some data might need to be retrieved from the external source Dato/backend
  const filterSelectItems = useMemo<FilterSelectItemProps[]>(
    () => [
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'objectTypes',
          placeholder: t('Subcategory/Object type'),
          options: objectTypeOptions,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: ['heavy_transport', 'machines']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'counties',
          placeholder: t('County'),
          options: countiesOptions,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: ['heavy_transport', 'machines']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'brand',
          placeholder: t('Brand'),
          options: brands,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: [
          'car',
          'recreational_vehicle',
          'light_transport',
          'heavy_transport',
          'machines'
        ]
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'familyName',
          placeholder: t('Model'),
          options: familyNames,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'fuel',
          placeholder: t('Fuel'),
          options: fuelOptions,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useBuyMethodFilterSelect,
        componentProps: {
          name: 'auctionType',
          placeholder: t('Buy method'),
          options: buyMethods,
          type: 'ToggleFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: [
          'car',
          'recreational_vehicle',
          'light_transport',
          'heavy_transport',
          'machines'
        ]
      },
      {
        hook: useYearModelFilterSelect,
        componentProps: {
          name: 'year',
          placeholder: t('Year model'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: [
          'car',
          'recreational_vehicle',
          'light_transport',
          'heavy_transport',
          'machines'
        ]
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'cardealerPrice',
          placeholder: t('Car dealer price'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          label: t('Car dealer price'),
          step: 2000,
          valueSuffix: t('SEK'),
          selectedMainCategory
        },
        displayLocation: 'default',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'gearbox',
          placeholder: t('Gearbox'),
          options: gearboxesOptions,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'buyNowAmount',
          placeholder: t('Cost'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          label: t('Car dealer price'),
          step: 2000,
          valueSuffix: t('SEK'),
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'vehicleBody',
          placeholder: t('Body'),
          options: vehicleBodyTypes,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'odometerReading',
          placeholder: t('Mileage'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          label: t('Odometer reading'),
          step: 1000,
          valueSuffix: t('unit.scandinavian_miles'),
          displayedValueFormatter: ([fromValue, toValue]: SliderType) => {
            return [
              formatMoneyWithoutCurrency(fromValue / 10),
              formatMoneyWithoutCurrency(toValue / 10)
            ];
          },
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'odometerReading',
          placeholder: t('Mileage'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          label: t('Odometer reading'),
          step: 1000,
          valueSuffix: t('unit.scandinavian_miles'),
          displayedValueFormatter: ([fromValue, toValue]: SliderType) => {
            return [
              formatMoneyWithoutCurrency(fromValue / 10),
              formatMoneyWithoutCurrency(toValue / 10)
            ];
          },
          minMaxValue: [0, 2500000],
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['heavy_transport', 'machines']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'operatingHours',
          placeholder: t('Operating hours'),
          options: [], // sliders cant have options
          type: 'SliderFilterSelect',
          label: t('Operating hours'),
          step: 100,
          valueSuffix: t('unit.hours'),
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['heavy_transport', 'machines']
      },
      {
        hook: useGeneralMultiFilterSelect,
        componentProps: {
          name: 'facilities',
          placeholder: t('Facilities'),
          options: facilities,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'recreational_vehicle', 'light_transport']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'numSeats',
          placeholder: t('Seats'),
          options: [],
          type: 'SliderFilterSelect',
          label: t('Seats'),
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'light_transport']
      },
      {
        hook: useGeneralSliderFilterSelect,
        componentProps: {
          name: 'hp',
          placeholder: t('Horsepower'),
          options: [],
          type: 'SliderFilterSelect',
          label: t('Horsepower'),
          valueSuffix: t('unit.horsepower'),
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: ['car', 'light_transport']
      },
      {
        hook: useOtherFilterSelect,
        componentProps: {
          name: 'other',
          placeholder: t('Misc'),
          options: otherOptions,
          type: 'MultiFilterSelect',
          selectedMainCategory
        },
        displayLocation: 'extra',
        mainCategories: [
          'car',
          'recreational_vehicle',
          'light_transport',
          'heavy_transport',
          'machines'
        ]
      }
    ],
    [
      brands,
      buyMethods,
      countiesOptions,
      facilities,
      familyNames,
      fuelOptions,
      gearboxesOptions,
      objectTypeOptions,
      otherOptions,
      t,
      vehicleBodyTypes,
      selectedMainCategory
    ]
  );

  const defaultFilters = useMemo<FilterSelectItemProps[]>(
    () =>
      filterSelectItems
        .filter(filter => filter.displayLocation === 'default')
        .filter(filter => filter.mainCategories.includes(selectedMainCategory)),
    [filterSelectItems, selectedMainCategory]
  );

  const extraFilters = useMemo<FilterSelectItemProps[]>(
    () =>
      filterSelectItems
        .filter(filter => filter.displayLocation === 'extra')
        .filter(filter => filter.mainCategories.includes(selectedMainCategory)),
    [filterSelectItems, selectedMainCategory]
  );

  const handleExpand = () => {
    setExpanded(!expand);
  };

  return (
    <>
      <IncludeContent from="tablet" data-testid="filter-selector">
        <FilterSelectorContainer>
          <FilterWrapper columns={defaultFilters.length}>
            {defaultFilters.map(filter => (
              <FilterSelectItem {...filter} key={filter.componentProps.name} />
            ))}
          </FilterWrapper>

          <Collapse isOpened={expand}>
            <Fade visible={expand}>
              <FilterWrapper columns={defaultFilters.length}>
                {extraFilters.map(filter => (
                  <FilterSelectItem
                    {...filter}
                    key={filter.componentProps.name}
                  />
                ))}
              </FilterWrapper>
            </Fade>
          </Collapse>

          <FilterToggleWrapper>
            <Button
              onClick={handleExpand}
              variant="flat"
              color="info"
              prefixIcon={
                <FilterIcon
                  width={'1.5rem'}
                  height={'1.5rem'}
                  title={t('FILTER_ICON_TOOLTIP')}
                />
              }
            >
              {expand ? t('Less filters') : t('More filters')}
            </Button>
          </FilterToggleWrapper>
        </FilterSelectorContainer>
      </IncludeContent>

      <FilterModal
        isOpen={filterModalIsOpen}
        onToggle={handleToggleModel}
        searchQuery={searchQuery}
      >
        {defaultFilters.map(filter => (
          <MobileFilterSelectItem
            {...filter}
            key={filter.componentProps.name}
          />
        ))}
        {extraFilters.map(filter => (
          <MobileFilterSelectItem
            {...filter}
            key={filter.componentProps.name}
          />
        ))}
      </FilterModal>
    </>
  );
};
